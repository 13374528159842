<template>
    <div>
        <div class="relative hidden xl:block">
            <img src="images/footer-background.png" class="w-full">
            <div class="absolute inset-0">
                <div class="flex container mx-auto">
                    <div class="w-5/12">
                        <div class="flex mt-32 xl:mt-48 2xl:mt-80 pl-14 space-x-6">
                            <span class="px-3 py-2 bg-white rounded-lg my-auto"><img src="images/jiipa.png" class="h-34"></span>
                            <label class="text-base xl:text-xl text-white font-bold px-3 border-l-4 border-white uppercase w-64"><!-- Japan India Industry Promotion Association -->{{$t('eh-sec2-title2')}}<br>{{$t('eh-sec2-title3')}}</label>
                        </div>
                        <div class="w-96 my-3 pl-14 text-sm text-white font-semibold">
                            <p v-html="$t('EUMP1-foot-mod-head-1-para')"></p>
                            <!-- Japan India Industry Promotion Association (JIIPA) is a Tokyo based NPO affilated with the Tokyo Metropotitan Government that promotes trade and business between Japan and India. -->
                        </div>
                        <div class="flex space-x-4 px-24">
                        <a href="https://www.facebook.com/npojiipa" target="_blank" class="bg-blue-500 p-2 rounded-full text-white my-auto" >
                            <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                        </a>
                        <a class="bg-blue-400 p-2 rounded-full text-white my-auto">
                            <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                        </a>
                        <a href="mailto:info@npo-jiipa.org" class="bg-white p-2 rounded-full my-auto">
                            <svg class="w-6 h-6  text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                        </a>
                        <a href="https://www.linkedin.com/company/jiipa" target="_blank" class="bg-blue-500 px-3 py-1 rounded-full text-white">
                            <span class="text-lg font-bold">in</span>
                        </a>
                        </div>
                    </div>

                   <div class="w-7/12 mt-16 ml-20 xl:mt-24 2xl:mt-56 xl:ml-48">
                        <div class="flex">
                            <div class="w-6/12">
                                <div class="flex flex-col text-white">
                                    <label class="text-xl font-semibold pb-5"><!-- Services -->{{$t('CADL-h12')}}</label>
                                    <div class="flex flex-col text-sm font-semibold space-y-5" >
                                        <a href="services#smart_recruit_scroll" class="hover:text-cyan"><!-- Smart Recruit -->{{$t('eh-hd-btn2')}}</a>
                                        <a href="services#scrollevent" class="hover:text-cyan"><!-- SPECIALIZED Business Service -->{{$t('eh-sec4-cd1-title')}}</a>
                                        <a href="services#btobmatchmaking" class="hover:text-cyan"><!-- B2B Matchmaking Business Delegation -->{{$t('foot-mod-head-2-con3')}}</a>
                                        <a href="services#sales-promotion-link" class="hover:text-cyan"><!-- Marketing / Sales Promotion -->{{$t('foot-mod-head-2-con4')}}</a>
                                        <a href="services#indiaJapan" class="hover:text-cyan"><!-- India-Japan Government Trading -->{{$t('foot-mod-head-2-con5')}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="w-3/12">
                                <div class="flex flex-col text-white">
                                    <label class="text-xl font-semibold pb-5"><!-- Explore -->{{$t('EUMP1-foot-mod-head-3')}}</label>
                                    <div class="flex flex-col text-sm font-semibold space-y-5">
                                        <a href="/" class="hover:text-cyan"><!-- Home -->{{$t('eh-nb-title1')}}</a>
                                        <a href="#" class="group relative hover:text-cyan">
                                            <!-- Membership -->{{$t('eh-nb-title3')}}
                                            <div class="opacity-0 w-32 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                              <!-- coming soon... -->{{$t('coming-soon')}}
                                              <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                          </div>
                                        </a>
                                        <a href="events" class="hover:text-cyan"><!-- Events -->{{$t('eh-nb-title4')}}</a>
                                        <router-link to="/newsletter" class="hover:text-cyan"><!-- Newsletter -->{{$t('CADL-h14')}}</router-link>
                                        <a href="gallery" class="hover:text-cyan"><!-- Gallery -->{{$t('eh-nb-title6')}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="w-3/12">
                                <div class="flex flex-col text-white">
                                    <label class="text-xl font-semibold pb-5"><!-- About Us -->{{$t('eh-nb-title2')}}</label>
                                    <div class="flex flex-col text-sm font-semibold space-y-5">
                                        <a href="about-us" class="hover:text-cyan"><!-- Company -->{{$t('EUMP1-foot-mod-head-4-con1')}}</a>
                                        <a href="contact-us" class="hover:text-cyan"><!-- Contact -->{{$t('EUMP1-foot-mod-head-4-con2')}}</a>
                                        <a href="#feedback" class="hover:text-cyan"><!-- Feedback -->{{$t('EUMP1-foot-mod-head-4-con3')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex mt-6">
                            <div class="w-7/12 flex">
                                <span>
                                    <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 24 24" width="50px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/></svg>
                                </span>
                                <a href="https://www.google.co.in/maps/search/6-ch%C5%8Dme-9-2+Shinbashi,+Minato+City,+Tokyo+10%5B%E2%80%A6%5D8bc1a48cbec5:0xa03fb83c88d333b!8m2!3d35.6616673!4d139.755943/@35.6616716,139.7537543,17z" target="_blank" class="text-lg text-white font-semibold pl-4 pr-16 hover:underline"><!-- Shinbashi Daiichi Honkan Bldg 2FD, 6-9-2 Shinbashi, Minato-ku, Tokyo 105-0004 JAPAN -->{{$t('EUCU-h8')}}</a>
                            </div>
                            <div class="w-5/12 flex">
                                <span>                        
                                    <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="50px" viewBox="0 0 24 24" width="50px"><rect fill="none" height="24" width="24"/><g><g><path d="M17.46,5c-0.06,0.89-0.21,1.76-0.45,2.59l1.2,1.2c0.41-1.2,0.67-2.47,0.76-3.79H17.46z M7.6,17.02 c-0.85,0.24-1.72,0.39-2.6,0.45v1.49c1.32-0.09,2.59-0.35,3.8-0.75L7.6,17.02z M16.5,3H20c0.55,0,1,0.45,1,1c0,9.39-7.61,17-17,17 c-0.55,0-1-0.45-1-1v-3.49c0-0.55,0.45-1,1-1c1.24,0,2.45-0.2,3.57-0.57c0.1-0.04,0.21-0.05,0.31-0.05c0.26,0,0.51,0.1,0.71,0.29 l2.2,2.2c2.83-1.45,5.15-3.76,6.59-6.59l-2.2-2.2c-0.28-0.28-0.36-0.67-0.25-1.02C15.3,6.45,15.5,5.25,15.5,4 C15.5,3.45,15.95,3,16.5,3z"/></g></g></svg>
                                </span>
                                <div class="flex flex-col text-lg text-white font-semibold pl-4 uppercase">
                                    <label><!-- TEL : 03-5733-5068 -->{{$t('tel')}}</label>
                                    <label><!-- FAX : 03-5733-5047--> {{$t('fax')}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ~~~~~~~~~~~~responsive ipad and iPad Pro-->
        <div class="relative hidden md:block xl:hidden">
            <img src="images/fotterBgMobile.png" class="w-full">
            <div class="absolute inset-0">
                <div class="flex container mx-auto">
                    <div class="w-8/12">
                         <div class="flex mt-14 md:mt-28 lg:mt-36 ml-2 md:ml-4">
                            <div class="w-7/12 flex">
                                <span>
                                    <svg class="fill-current text-white w-6 md:w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/></svg>
                                </span>
                                <a href="https://www.google.co.in/maps/search/6-ch%C5%8Dme-9-2+Shinbashi,+Minato+City,+Tokyo+10%5B%E2%80%A6%5D8bc1a48cbec5:0xa03fb83c88d333b!8m2!3d35.6616673!4d139.755943/@35.6616716,139.7537543,17z" target="_blank" class="text-xs md:text-sm lg:text-lg text-white font-semibold pl-2 md:pl-4 pr-2 md:pr-4 lg:pr-16 hover:underline"><!-- Shinbashi Daiichi Honkan Bldg 2FD, 6-9-2 Shinbashi, Minato-ku, Tokyo 105-0004 JAPAN -->{{$t('EUCU-h8')}}</a>
                            </div>
                            <div class="w-5/12 flex">
                                <span>                        
                                    <svg class="fill-current text-white w-6 md:w-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><rect fill="none" height="24" width="24"/><g><g><path d="M17.46,5c-0.06,0.89-0.21,1.76-0.45,2.59l1.2,1.2c0.41-1.2,0.67-2.47,0.76-3.79H17.46z M7.6,17.02 c-0.85,0.24-1.72,0.39-2.6,0.45v1.49c1.32-0.09,2.59-0.35,3.8-0.75L7.6,17.02z M16.5,3H20c0.55,0,1,0.45,1,1c0,9.39-7.61,17-17,17 c-0.55,0-1-0.45-1-1v-3.49c0-0.55,0.45-1,1-1c1.24,0,2.45-0.2,3.57-0.57c0.1-0.04,0.21-0.05,0.31-0.05c0.26,0,0.51,0.1,0.71,0.29 l2.2,2.2c2.83-1.45,5.15-3.76,6.59-6.59l-2.2-2.2c-0.28-0.28-0.36-0.67-0.25-1.02C15.3,6.45,15.5,5.25,15.5,4 C15.5,3.45,15.95,3,16.5,3z"/></g></g></svg>
                                </span>
                                <div class="flex flex-col text-xs md:text-sm lg:text-lg text-white font-semibold pl-2 md:pl-4">
                                    <label><!-- TEL : 03-5733-5068 -->{{$t('tel')}}</label>
                                    <label><!-- FAX : 03-5733-5047 -->{{$t('fax')}}</label>
                                </div>
                            </div>
                        </div>

                       <div class="ml-16 pt-6">
                            <div class="flex mt-14 lg:mt-32 ml-8 lg:ml-14 space-x-6">
                            <span class="bg-white rounded-lg px-2 my-auto"><img src="images/jiipa.png" class="h-34 my-3"></span>
                            <label class="text-xl text-white font-bold px-3 border-l-4 border-white uppercase w-64"><!-- Japan India Industry Promotion Association -->{{$t('eh-sec2-title2')}}</label>
                        </div>
                        <div class="w-96 my-3 pl-14 text-sm text-white font-semibold">
                            <p v-html="$t('EUMP1-foot-mod-head-1-para')"></p>
                                <!-- Japan India Industry Promotion Association (JIIPA) is a Tokyo based NPO affilated with the Tokyo Metropotitan Government that promotes trade and business between Japan and India. -->
                        </div>
                        <div class="flex space-x-4 px-24">
                            <a href="https://www.facebook.com/npojiipa" class="bg-blue-500 p-2 rounded-full text-white my-auto" target="_blank">
                                <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                            </a>
                            <a class="bg-blue-400 p-2 rounded-full text-white my-auto">
                                <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                            </a>
                            <a href="mailto:info@npo-jiipa.org" class="bg-white p-2 rounded-full my-auto">
                                <svg class="w-6 h-6  text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                            </a>
                            <a class="bg-blue-500 px-3 py-1 rounded-full text-white">
                                <span class="text-lg font-bold">in</span>
                            </a>
                        </div>
                       </div>
                    </div>

                   <div class="w-4/12 mt-10 lg:ml-20">
                        <div class="flex-col space-y-10">
                                <div class="flex flex-col text-white">
                                     <label class="text-2xl font-semibold pb-2"><!-- Services -->{{$t('CADL-h12')}}</label>
                                    <div class="flex flex-col text-xs md:text-sm font-semibold space-y-1 md:space-y-4">
                                        <a href="services#smart_recruit_scroll" target="_blank" class="hover:text-cyan"><!-- Smart Recruit --> {{$t('eh-hd-btn2')}}</a>
                                        <a href="services#scrollevent" class="hover:text-cyan"><!-- SPECIALIZED Business Service --> {{$t('eh-sec4-cd1-title')}}</a>
                                        <a href="services#btobmatchmaking" class="hover:text-cyan"><!-- B2B Matchmaking Business Delegation -->{{$t('foot-mod-head-2-con3')}}</a>
                                        <a href="services#sales-promotion-link" class="hover:text-cyan"><!-- Marketing / Sales Promotion -->{{$t('foot-mod-head-2-con4')}}</a>
                                        <a href="services#indiaJapan" class="hover:text-cyan"><!-- India-Japan Government Trading -->{{$t('foot-mod-head-2-con5')}}</a>
                                    </div>
                                </div>
                                <div class="flex lg:flex-col space-x-6 lg:space-x-0">
                                    <div class="flex flex-col text-white">
                                    <label class="text-2xl font-semibold pb-2"><!-- Explore -->{{$t('EUMP1-foot-mod-head-3')}}</label>
                                    <div class="flex flex-col text-sm font-semibold space-y-4">
                                        <a href="/" class="hover:text-cyan"><!-- Home -->{{$t('eh-nb-title1')}}</a>
                                        <a href="#" class="group relative hover:text-cyan"><!-- Membership -->{{$t('eh-nb-title3')}}
                                            <div class="opacity-0 w-32 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                              <!-- coming soon... -->{{$t('coming-soon')}}
                                              <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                          </div>
                                        </a>
                                        <a href="events" class="hover:text-cyan"><!-- Events -->{{$t('eh-nb-title4')}}</a>
                                        <a href="newsletter" class="hover:text-cyan"><!-- Newsletter -->{{$t('CADL-h14')}}</a>
                                        <a href="gallery" class="hover:text-cyan"><!-- Gallery -->{{$t('eh-nb-title6')}}</a>
                                    </div>
                                </div>
                                <div class="flex flex-col text-white">
                                    <label class="text-2xl font-semibold pb-2"><!-- About Us -->{{$t('eh-nb-title2')}}</label>
                                    <div class="flex flex-col text-sm font-semibold space-y-4">
                                        <a href="about-us" class="hover:text-cyan"><!-- Company -->{{$t('EUMP1-foot-mod-head-4-con1')}}</a>
                                        <a href="contact-us" class="hover:text-cyan"><!-- Contact -->{{$t('EUMP1-foot-mod-head-4-con2')}}</a>
                                        <a href="#feedback" class="hover:text-cyan"><!-- Feedback -->{{$t('EUMP1-foot-mod-head-4-con3')}}</a>
                                    </div>
                                </div>
                                </div>
                        </div>

                       
                    </div>
                </div>
            </div>

        </div>

        <!-- ~~~~~~~~~~~~responsive mobile-->
        <div class="relative md:hidden">
            <img src="images/fotterBgMobile.png" class="w-full" style="height: 20rem;">
            <div class="absolute inset-0">
                <div class="flex mx-4 mt-10">
                    <div class="flex flex-col text-white">
                        <label class="text-xl font-semibold pb-2"><!-- Services -->{{$t('CADL-h12')}}</label>
                        <div class="flex flex-col text-xs font-semibold space-y-1 md:space-y-4">
                            <a href="services#smart_recruit_scroll" target="_blank" class="hover:text-cyan"><!-- Smart Recruit --> {{$t('eh-hd-btn2')}}</a>
                            <a href="services#scrollevent" class="hover:text-cyan"><!-- SPECIALIZED Business Service --> {{$t('eh-sec4-cd1-title')}}</a>
                            <a href="services#btobmatchmaking" class="hover:text-cyan"><!-- B2B Matchmaking Business Delegation -->{{$t('foot-mod-head-2-con3')}}</a>
                            <a href="services#sales-promotion-link" class="hover:text-cyan"><!-- Marketing / Sales Promotion -->{{$t('foot-mod-head-2-con4')}}</a>
                            <a href="services#indiaJapan" class="hover:text-cyan"><!-- India-Japan Government Trading -->{{$t('foot-mod-head-2-con5')}}</a>
                        </div>
                    </div>
                    <div class="flex lg:flex-col space-x-6">
                        <div class="flex flex-col text-white">
                            <label class="text-xl font-semibold pb-2"><!-- Explore -->{{$t('EUMP1-foot-mod-head-3')}}</label>
                            <div class="flex flex-col text-xs font-semibold space-y-1">
                                <a href="/" class="hover:text-cyan"><!-- Home -->{{$t('eh-nb-title1')}}</a>
                                <a href="#" class="group relative hover:text-cyan"><!-- Membership -->{{$t('eh-nb-title3')}}
                                    <div class="opacity-0 w-32 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                        <!-- coming soon... -->{{$t('coming-soon')}}
                                      <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                  </div>
                                </a>
                                <a href="events" class="hover:text-cyan"><!-- Events -->{{$t('eh-nb-title4')}}</a>
                                <a href="newsletter" class="hover:text-cyan"><!-- Newsletter -->{{$t('CADL-h14')}}</a>
                                <a href="gallery" class="hover:text-cyan"><!-- Gallery -->{{$t('eh-nb-title6')}}</a>
                            </div>
                        </div>
                        <div class="flex flex-col text-white">
                            <label class="text-xl font-semibold pb-2"><!-- About Us -->{{$t('eh-nb-title2')}}</label>
                            <div class="flex flex-col text-xs font-semibold space-y-1">
                                <a href="about-us" class="hover:text-cyan"><!-- Company -->{{$t('EUMP1-foot-mod-head-4-con1')}}</a>
                                <a href="contact-us" class="hover:text-cyan"><!-- Contact -->{{$t('EUMP1-foot-mod-head-4-con2')}}</a>
                                <a href="#feedback" class="hover:text-cyan"><!-- Feedback -->{{$t('EUMP1-foot-mod-head-4-con3')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex container mx-auto">
                    <div class="w-8/12">
                        <div class="lg:ml-16 mr-16 pt-4">
                            <div class="flex space-x-4 px-24">
                                <a href="https://www.facebook.com/npojiipa" class="bg-blue-500 p-2 rounded-full text-white my-auto" target="_blank">
                                    <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                                </a>
                                <a class="bg-blue-400 p-2 rounded-full text-white my-auto">
                                    <svg class="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                                </a>
                                <a href="mailto:info@npo-jiipa.org" class="bg-white p-2 rounded-full my-auto">
                                    <svg class="w-6 h-6  text-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                </a>
                                <a class="bg-blue-500 px-3 py-1 rounded-full text-white">
                                    <span class="text-lg font-bold">in</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-copyrightBG">
            <div class="lg:flex md:flex justify-between px-4 py-2 lg:mx-14 space-y-4 md:space-y-0">
                <div class="flex space-x-6">
                    <div class="w-44">
                        <a href="https://www.sekai-ichi.com/?__cf_chl_jschl_tk__=pmd_vxj8zijURzOE02_hUPMW4k85h4dOsSdx7Mxd06RMjp0-1629968006-0-gqNtZGzNAdCjcnBszQrR" target="_blank">
                            <img src="images/sekai-logo.png" class="rounded-full">
                        </a>
                    </div>
                    <div class="font-bold uppercase text-xs text-gray-100 my-auto">
                        <div class="underline">
                            <label><!-- Website Design & Developed by -->{{$t('EUMP1-foot-mod-com-des')}}</label>
                        </div>
                        <div class="underline hover:text-blue-300">
                            <a href="https://www.sekai-ichi.com/?__cf_chl_jschl_tk__=pmd_vxj8zijURzOE02_hUPMW4k85h4dOsSdx7Mxd06RMjp0-1629968006-0-gqNtZGzNAdCjcnBszQrR" target="_blank"><!-- Sekai-Ichi Engineering And IT Solutions Pvt. Ltd. --> {{$t('EUMP1-foot-mod-com-des1')}}</a>
                        </div>
                    </div>
                </div>

                <div class="font-bold uppercase text-xs text-center md:text-right text-gray-100 my-auto md:pt-2">
                    <div><label><!-- Copyright © -->{{$t('EUMP1-foot-mod-com-des-2')}} {{ new Date().getFullYear() }}</label></div>
                    <div><label><!-- Japan India Industry Promotion Association (NPO) All Rights Reserved. -->{{$t('eh-sec2-title1')}}</label></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
export default {
 data: function() {
     return {
        recruitClientUrl: process.env.VUE_APP_SMART_RECRUIT_URL,
    }
},
  components: {

  },
  computed: {
        ...mapGetters({
            user: 'auth/user',
        })
    }, 
    methods: {
    // reloadPage() {
    //   // window.location.reload();
    //     this.$router.push({path:'/services', params:{id:'scrollevent'} })
    // }
      //   scrollToTop() {
      //   window.scrollTo(0,0);
      // }
  },
  mounted() {
    // SET BACKGROUND IMAGE FOR FOOTER 
  //   window.addEventListener('load', function(){
  //     var footer = document.querySelector('.footer-section')
  //     footer.style.setProperty('--footer-image', 'url("' + process.env.BASE_URL + 'images/footer-background.png' + '")')
  // })
}
};

</script>

<style scoped>
/*:root {
    --footer-image: '';
}
.footer-section{
    background-image: var(--footer-image);
    background-repeat: no-repeat;
    height:32rem;
    background-size: 100% 100% !important;
}

@media only screen and (min-width: 1900px) {
  .footer-section{
     background-image: var(--footer-image);
     background-repeat: no-repeat;
     height:36rem;
     background-size: 100% 100% !important;
 }  
}*/
</style>
