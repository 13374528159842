export default [

{
	path: '/about-us',
	component: () => import('../components/AboutUsParent.vue'),
	children:[
		{
			path: '/about-us',
			component: () => import('../components/AboutUs.vue'),
			name: 'about-us',
			meta: {	
				guest: true,
				needsAuth: false		
			},
		},

		 {
			path: '/key-official',
			component: () => import('../components/KeyOfficials.vue'),
			name: 'key-official',
			meta: {	
				guest: true,
				needsAuth: false	
			}
		},
	]
},
 


]